import { useRouter } from "next/router";
import { useState } from "react";
import { toast } from "react-toastify";

const Home = () => {
	const [certificateId, setCertificateId] = useState(
		process.env.NODE_ENV === "development" ? "LUEPY062200521" : ""
	);
	const router = useRouter();

	const validateCertificate = () => {
		certificateId
			? router.push(`/certificate/${certificateId.toUpperCase()}`)
			: toast.error("Missing Certificate ID");
	};
	return (
		<>
			<div className="bg-graphics mt-6 overflow-hidden bg-white">
				<div className="mx-auto flex min-h-navScreen max-w-7xl flex-col items-center justify-center px-4 sm:px-6 md:flex-row">
					<div className="flex w-full items-center justify-center md:w-2/3">
						<div className="w-full md:mx-auto">
							<h1>
								<span className="mt-1 flex flex-wrap justify-center gap-2 text-3xl font-extrabold tracking-normal sm:text-5xl md:text-4xl xl:text-6xl">
									<span className="text-gray-900">
										Verify your
									</span>
									<span className="text-lu">LetsUpgrade</span>
									<span className="text-gray-900">
										Certification
									</span>
								</span>
							</h1>
							<div className="mt-8 flex flex-col items-center sm:mx-auto">
								<p className="text-center text-base font-medium text-gray-900">
									The sweat and hard work you've put into your
									self is just a click away
								</p>
								<form
									className="mt-3 w-full sm:flex sm:max-w-md"
									onSubmit={(e) => e?.preventDefault()}
								>
									<label htmlFor="email" className="sr-only">
										Email
									</label>
									<input
										type="text"
										name="certificateId"
										id="certificateId"
										value={certificateId}
										onChange={(e) =>
											setCertificateId(
												e.target.value.toUpperCase()
											)
										}
										className="block w-full rounded-md border-gray-300 py-3 text-base placeholder-gray-500 shadow-sm focus:border-lu focus:ring-lu sm:flex-1"
										placeholder="Enter Certificate Id"
									/>
									<div
										className="mt-3 w-full cursor-pointer rounded-md border border-transparent bg-gray-900 px-6 py-3 text-center text-base font-medium text-white shadow-sm hover:bg-gray-900 focus:outline-none sm:mt-0 sm:ml-3 sm:inline-flex sm:w-auto sm:flex-shrink-0 sm:items-center"
										onClick={validateCertificate}
									>
										Verify Certificate
									</div>
								</form>
								<p className="mt-3 text-center text-sm text-gray-500">
									We care about the protection of your data.
									Read our
									<a
										href="https://letsupgrade.in/privacy"
										target="_blank"
										className="ml-1 font-medium text-gray-900 underline"
									>
										Privacy Policy
									</a>
									.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Home;
